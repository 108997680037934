import { useState, useEffect, useCallback } from "react";
import { motion } from "framer-motion";
import { useHttp } from "#hooks/http.hook";
import { Spinner } from "#formComponents";

export const WORK_DAY_DEFAULT = { id: "8", label: "8", color: "#73d000" };

export function SelectWorkDayType({ currentType, setCurrentType }) {
  const [listWorkDayType, setListWorkDayType] = useState([WORK_DAY_DEFAULT]);
  const [customHour, setCustomHour] = useState(WORK_DAY_DEFAULT.id);
  const { loading, request } = useHttp();

  const readData = useCallback(async () => {
    try {
      const result = await request("/daytype", "GET", {}, {});
      setListWorkDayType(
        !result.records
          ? []
          : [
              ...result.records.map((row) => ({
                id: row.short_name || row.name,
                label: row.short_name,
                color: row.color,
              })),
              WORK_DAY_DEFAULT,
            ]
      );
    } catch (e) {}
  }, [request]);

  const onChangeHandle = (e) => {
    let valNum = e.target.value;
    valNum = valNum.replace(/[^0-9.]/g, "");
    valNum = valNum.replace(/,/g, ".");
    if (valNum > 24) valNum = 24;
    if (valNum.length > 5) {
      valNum = valNum.slice(0, 5);
    }

    setCustomHour(valNum);
  };

  const selectTypeHandle = (type) => {
    setCurrentType(type);
  };

  useEffect(() => {
    if (customHour === "" || isNaN(customHour)) {
      return;
    }

    const currentNumeric = listWorkDayType.find(
      (type) => isNaN(type.id) === false
    );
    const newDayType = {
      ...currentNumeric,
      id: customHour,
      label: customHour.toString(),
    };
    setCurrentType(newDayType);
    setListWorkDayType((prev) =>
      prev.map((type) => (isNaN(type.id) ? type : newDayType))
    );
  }, [customHour]);

  useEffect(() => {
    readData();
  }, []);

  return (
    <div className="flex flex-wrap items-center gap-3">
      {loading === true && (
        <div className="absolute flex w-full mt-14 justify-center">
          <Spinner />
        </div>
      )}
      {listWorkDayType.map((rowType) => (
        <motion.div
          key={rowType.id}
          onClick={() => selectTypeHandle(rowType)}
          whileHover={{ scale: 1.1 }}
          className={`flex justify-center items-center w-12 h-9 m-0.5 font-medium cursor-pointer rounded-lg shadow-lg shadow-color01/30 hover:brightness-75`.concat(
            rowType.id === currentType.id ? " border-2" : ""
          )}
          style={{ backgroundColor: rowType.color }}
        >
          <span className="without-selection">
            {isNaN(rowType.id) || customHour === ""
              ? rowType.label
              : customHour}
          </span>
        </motion.div>
      ))}
      <input
        type="number"
        value={customHour}
        onChange={onChangeHandle}
        className="w-17 h-9 border border-color02 text-color01 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
        required
        min="1"
        max="24"
      />
    </div>
  );
}
