import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "#hooks/lang.hook";
import { motion, AnimatePresence, useMotionValue } from "framer-motion";
import {
  useWeekNames,
  getLocalMonth,
  monthComparing,
  dateComparing,
} from "#common/dateFactory";
import {
  Button,
  Spinner,
  SelectPeriod,
  SelectWorkDayType,
  WORK_DAY_DEFAULT,
  Breadcrumb,
} from "#formComponents";
import { useParams } from "react-router-dom";
import { useHttp } from "#hooks/http.hook";
import { useSelector } from "react-redux";
import { PicPlus, PicPeriod } from "#svg";
import { useNavigate } from "react-router-dom";

export function Tabel() {
  const { id } = useParams();
  // const user = useSelector((state) => state.user.value);
  const currentLang = useSelector((state) => state.lang.value);
  const { loading, request } = useHttp();
  const [noneditable, setNoneditable] = useState(false);
  const [currentType, setCurrentType] = useState(WORK_DAY_DEFAULT);
  const navigate = useNavigate();

  const [showPeriod, setShowPeriod] = useState(false);
  const [edited, setEdited] = useState(false);

  const [dataDoc, setDataDoc] = useState({});
  const [people, setPeople] = useState([]);
  const [data, setData] = useState([]);
  const [period, setPeriod] = useState({ start: 0, end: 0 });
  const [isDragging, setDragging] = useState(false);

  const [textFilter, setTextFilter] = useState("");

  const [arrDays, setArrDays] = useState([]);
  const translation = useTranslation([
    { group: "menu", name: "desktop" },
    { group: "tabel", name: "employee" },
    { group: "common", name: "search" },
    { group: "common", name: "save" },
  ]);
  const [translationDays] = useState(useWeekNames(true));

  let filteredPeople = [];

  const saveData = useCallback(
    async (id, people, data) => {
      if (people && people.length) {
        try {
          await request(
            "/tabel",
            "POST",
            {
              id,
              details: JSON.stringify({
                people,
                data,
              }),
            },
            {}
          );
          setEdited(false);
          navigate("/timesheet");
        } catch (e) {}
      }
    },
    [request]
  );

  const periodHandle = () => {
    setShowPeriod(true);
  };

  const hideFormHandle = () => {
    setShowPeriod(false);
  };

  const applyData = ({ start, end }) => {
    setPeriod({ start, end });
    setShowPeriod(false);
  };

  const selectHandle = (peopleId, date, editable) => {
    if (editable === false) {
      return;
    }
    setData((prev) => {
      setEdited(true);
      const currentRow = prev.find(
        (rowFind) => rowFind.peopleId === peopleId && rowFind.date === date
      );
      if (currentRow) {
        return prev.map((rowPrev) =>
          rowPrev.peopleId === peopleId && rowPrev.date === date
            ? { ...rowPrev, value: currentType }
            : rowPrev
        );
      }
      return [
        ...prev,
        { id: crypto.randomUUID(), peopleId, date, value: currentType },
      ];
    });
  };

  const onChangeSearchHendle = (e) => {
    setTextFilter(e.target.value);
  };

  const saveHandle = (e) => {
    saveData(id, people, data);
  };

  const addHandle = (e) => {};

  const getData = (peopleId, dayOfMonth) => {
    const found = data.find(
      (row) => row.peopleId === peopleId && row.date === dayOfMonth
    );
    return found ? found.value : {};
  };

  const readData = useCallback(
    async (id) => {
      try {
        const result = await request(`/tabel/${id}`, "GET", "", {});
        setEdited(undefined);
        if (result?.records?.length) {
          const currentDoc = result.records[0];
          const details = currentDoc?.details;
          const dataMonth = new Date(currentDoc.month_salary);
          let firstDay = new Date(
            dataMonth.getFullYear(),
            dataMonth.getMonth(),
            1
          ).getDate();
          let lastDay = new Date(
            dataMonth.getFullYear(),
            dataMonth.getMonth() + 1,
            0
          ).getDate();
          const currentM = new Date();

          setDataDoc({
            dateClose: details.dateClose,
            department: currentDoc.department,
            month: getLocalMonth(dataMonth, currentLang),
            currentMonth: new Date(
              dataMonth.getFullYear(),
              dataMonth.getMonth(),
              1
            ),
          });
          setNoneditable(
            details.dateClose
              ? dateComparing(
                  new Date(details.dateClose),
                  new Date(dataMonth.getFullYear(), dataMonth.getMonth() + 1, 0)
                )
              : false
          );
          if (monthComparing(dataMonth, currentM)) {
            lastDay = currentM.getDate();
          }
          if (lastDay - firstDay > 8) {
            firstDay = lastDay - 7;
          }
          if (monthComparing(dataMonth, new Date())) {
            setPeriod({ start: firstDay, end: lastDay });
          } else {
            setPeriod({ start: 1, end: lastDay });
          }
          setPeople(details.people || []);
          setData(details.data || []);
        } else {
          setDataDoc({});
        }
      } catch (e) {}
    },
    [request]
  );

  useEffect(() => {
    readData(id);
  }, [id]);

  useEffect(() => {
    if (!dataDoc.currentMonth) return;

    const endOfMonth = new Date(
      dataDoc.currentMonth.getFullYear(),
      dataDoc.currentMonth.getMonth() + 1,
      0
    );
    const newArr = [];
    for (
      let day = new Date(dataDoc.currentMonth);
      day <= endOfMonth;
      day.setDate(day.getDate() + 1)
    ) {
      const dayNumber = day.getDate();
      if (period.start <= dayNumber && period.end >= dayNumber) {
        newArr.push({
          id: dayNumber,
          day: day.toISOString(),
          dayNumber: day.getDay(),
          name: translationDays[day.getDay()],
        });
      }
    }
    setArrDays(newArr);
  }, [dataDoc, period, translationDays]);

  if (textFilter === "") {
    filteredPeople = people;
  } else {
    filteredPeople = people.filter((obj) =>
      obj.name.toLowerCase().includes(textFilter.toLowerCase())
    );
  }

  const dragOriginY = useMotionValue(0);
  const moveItem = (ss, fff) => {
    //console.log(ss, fff);
  };

  return (
    <div>
      {showPeriod && (
        <SelectPeriod
          currentStart={period.start}
          currentEnd={period.end}
          hideForm={hideFormHandle}
          applyData={applyData}
        />
      )}
      <div className="pl-[5rem] pr-[1rem] min-h-[calc(100svh)] flex flex-col gap-3">
        <Breadcrumb
          arr={[
            { href: "/timesheet", name: translation.desktop },
            { href: "#", name: dataDoc.department },
          ]}
        />
        <div className="flex flex-wrap items-center gap-3 mx-2">
          <span className="without-selection cursor-default">
            {dataDoc.month}
          </span>
          {noneditable ? null : (
            <Button onClickHandle={addHandle} icon={<PicPlus />} size="small" />
          )}
          {noneditable ? null : (
            <Button
              onClickHandle={periodHandle}
              icon={<PicPeriod />}
              size="small"
            />
          )}
          <input
            type="text"
            value={textFilter}
            className="flex flex-grow min-w-[10em] border border-color05 text-color01 text-sm rounded-lg focus:ring-color03 focus:border-color03 block p-2"
            onChange={onChangeSearchHendle}
            placeholder={translation.search}
          />
          {noneditable
            ? null
            : edited && (
                <Button
                  onClickHandle={saveHandle}
                  label={translation.save}
                  size="small"
                />
              )}
        </div>
        {noneditable ? null : (
          <div className="mx-2 sticky left-0 top-0 z-[11] bg-color04 w-full">
            <SelectWorkDayType
              currentType={currentType}
              setCurrentType={setCurrentType}
            />
          </div>
        )}
        {loading === true && (
          <div className="absolute flex w-full mt-12 justify-center">
            <Spinner />
          </div>
        )}
        <div className="flex">
          <AnimatePresence>
            <motion.table className="text-left text-sm font-light mx-2 overflow-y-auto overflow-x-hidden">
              <thead className="border-b font-medium bg-color04">
                <tr>
                  <th
                    scope="col"
                    key={"col"}
                    className={"sticky left-0 without-selection cursor-default px-2 bg-color04 z-10".concat(
                      noneditable ? " top-0 " : " top-10 "
                    )}
                  >
                    {translation.employee}
                  </th>
                  {arrDays.map((rowDay) => {
                    const editable = dataDoc.dateClose
                      ? new Date(dataDoc.dateClose) < new Date(rowDay.day)
                      : true;
                    return (
                      <th
                        scope="col"
                        className={"without-selection pb-1 sticky bg-color04".concat(
                          noneditable ? " top-0 " : " top-10 "
                        )}
                        key={rowDay.id}
                      >
                        <div
                          className={"flex flex-col justify-center items-center w-10".concat(
                            editable ? " text-color01" : " text-[#6c757d]"
                          )}
                        >
                          <span className="without-selection cursor-default font-small text-sm">
                            {rowDay.id}
                          </span>
                          <span className="without-selection cursor-default font-small text-xs">
                            {rowDay.name}
                          </span>
                        </div>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {filteredPeople.map((row, i) => {
                  return (
                    <motion.tr
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      transition={{
                        duration: 0.05,
                        delay: 0.05 * filteredPeople.indexOf(row),
                      }}
                      className={
                        "transition duration-300 ease-in-out hover:bg-color03 h-10"
                      }
                      key={row.id}
                      id={row.id}
                    >
                      <td
                        className="sticky left-0 h-10 whitespace-nowrap font-medium px-2 rounded-l-md cursor-default"
                        key={`col${row.id}`}
                      >
                        <motion.div
                          //animate={isDragging ? onTop : flat}
                          whileHover={{ scale: 1.03 }}
                          whileTap={{ scale: 1.12 }}
                          drag="y"
                          dragOriginY={dragOriginY}
                          dragConstraints={{ top: 0, bottom: 0 }}
                          dragElastic={1}
                          onDragStart={() => setDragging(true)}
                          onDragEnd={(e) => {
                            setDragging(false);
                          }}
                          onDrop={() => {
                            console.log("POLUCHEN", row.id);
                          }}
                          onDragOver={(e) => {
                            e.preventDefault();
                          }}
                          onDrag={(e, { point }) => moveItem(row.id, point.y)}
                          className={"flex flex-col pt-0 px-3 rounded-lg bg-color04 shadow-lg shadow-color01/50".concat(
                            row.position ? "" : " py-3"
                          )}
                        >
                          <span>{row.name}</span>
                          {row.position && (
                            <span className="flex self-end p-0 mt-[-0.4rem] text-[0.6rem] text-color01">
                              {row.position}
                            </span>
                          )}
                        </motion.div>
                      </td>
                      {arrDays.map((rowDay) => {
                        const currentValue = getData(row.id, rowDay.id);
                        const newDayDate = new Date(rowDay.day);
                        const editable = dataDoc.dateClose
                          ? new Date(dataDoc.dateClose) < newDayDate
                          : true;
                        let fired = row.fired
                          ? new Date(row.fired) < newDayDate
                          : false;
                        if (row.start && fired === false) {
                          newDayDate.setHours(23, 59, 59, 999);
                          fired = new Date(row.start) > newDayDate;
                        }
                        const currentColor = currentValue?.color
                          ? currentValue?.color.charAt(0) === "#"
                            ? currentValue?.color
                            : currentValue?.color
                                .replace("bg-[", "")
                                .replace("]", "")
                          : "";

                        return (
                          <td className="table-cell" key={rowDay.id}>
                            {!fired && (
                              <motion.div
                                onClick={() =>
                                  selectHandle(row.id, rowDay.id, editable)
                                }
                                whileHover={editable ? { scale: 1.1 } : {}}
                                className={`flex justify-center items-center w-10 h-8 m-0.5 p-0 font-medium rounded-lg shadow-lg shadow-color01/50 `.concat(
                                  editable
                                    ? " cursor-pointer hover:brightness-75"
                                    : " "
                                )}
                                style={{ backgroundColor: currentColor }}
                              >
                                <span className="without-selection">
                                  {currentValue?.label}
                                </span>
                              </motion.div>
                            )}
                          </td>
                        );
                      })}
                    </motion.tr>
                  );
                })}
              </tbody>
            </motion.table>
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
}
