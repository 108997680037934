import { useTranslation } from "#hooks/lang.hook";

export function useWeekNames(needsArray = false) {
  const translation = useTranslation([
    { group: "common", name: "day1" },
    { group: "common", name: "day2" },
    { group: "common", name: "day3" },
    { group: "common", name: "day4" },
    { group: "common", name: "day5" },
    { group: "common", name: "day6" },
    { group: "common", name: "day7" },
  ]);

  return needsArray
    ? [
        translation.day7,
        translation.day1,
        translation.day2,
        translation.day3,
        translation.day4,
        translation.day5,
        translation.day6,
        translation.day7,
      ]
    : translation;
}

export function useWeekName(weekNum) {
  const translation = useTranslation([
    { group: "common", name: "day" + weekNum.toString() || "" },
  ]);
  return translation.day;
}

export const useDateNameToLocatFormat = (val) => {
  const translation = useTranslation([
    { group: "common", name: "day" + new Date(val).getDay() },
  ]);
  return val ? translation.day : "";
};

export function addDays(date, days) {
  date.setDate(date.getDate() + days);
  return date;
}

export function addMinutes(date, minutes) {
  return new Date(date.getTime() + minutes * 60000);
}

export function addMinutesToFormatTime(time, minutes) {
  const current = new Date("2022-01-01 " + time);
  const newTime = addMinutes(current, minutes);
  return (
    formatTime(newTime.getHours()) + ":" + formatTime(newTime.getMinutes())
  );
}

export const formatTime = (val) => {
  return val < 10 ? "0" + val : val;
};

export const dateToLocatFormat = (val) => {
  if (!val) return undefined;
  return val.substr(8, 2) + "." + val.substr(5, 2) + "." + val.substr(0, 4);
};

export const getCurrentDateInFormat = () => {
  return getDateInDashFormat(new Date());
};

export const getDateInDashFormat = (val) => {
  return (
    val.getFullYear() +
    "-" +
    formatTime(val.getMonth() + 1) +
    "-" +
    formatTime(val.getDate())
  );
};

export const getFormatedTime = (dateToFormat) => {
  return (
    formatTime(dateToFormat.getHours()) +
    ":" +
    formatTime(dateToFormat.getMinutes())
  );
};

export const getCurrentTime = () => {
  const current = new Date();
  return getFormatedTime(current);
};

export const getCurrentDay = () => {
  return new Date().getDay();
};

export const getLocalMonth = (date, lang = "en") => {
  const options = { year: "numeric", month: "long" };
  const formattedDate = date.toLocaleDateString(lang, options);
  return formattedDate;
};

export const getNextDay = (day, currentDate) => {
  const current = new Date(currentDate);
  const currentDay = current.getDay();
  return currentDay === day
    ? addDays(current, 7)
    : currentDay < day
    ? addDays(current, day - currentDay)
    : addDays(current, 7 - currentDay + day);
};

export const getFirstDayOfNextMonth = (date) => {
  let year = date.getFullYear();
  let month = date.getMonth() + 1;

  if (month > 11) {
    month = 0;
    year++;
  }

  return new Date(year, month, 1);
};

export const getFirstDayOfPreviousMonth = (date) => {
  let year = date.getFullYear();
  let month = date.getMonth() - 1;

  if (month < 0) {
    month = 11;
    year--;
  }

  return new Date(year, month, 1);
};

export const isoToString = (isoString) => {
  const date = new Date(isoString);

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  return `${day}.${month}.${year}`;
};

export const isoToStringWithTime = (isoString) => {
  const date = new Date(isoString);

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
};

export const monthComparing = (date1, date2) => {
  return (
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  );
};

export const dateComparing = (date1, date2) => {
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  );
};
