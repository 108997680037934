import { useState, useCallback, useEffect } from "react";
import { useTranslation } from "#hooks/lang.hook";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useHttp } from "#hooks/http.hook";
import { Spinner, SelectMonth } from "#formComponents";
import { useSelector } from "react-redux";
import { getLocalMonth } from "#common/dateFactory";

export function TabelList() {
  const currentLang = useSelector((state) => state.lang.value);
  const navigate = useNavigate();
  const [listDocs, setListDocs] = useState();
  const [period, setPeriod] = useState(() => {
    const savedPeriod = window.localStorage.getItem("period");
    return savedPeriod ? new Date(savedPeriod) : new Date();
  });
  const [textFilter, setTextFilter] = useState("");
  const { loading, request } = useHttp();
  let filteredDocs = [];

  const readData = useCallback(
    async (period) => {
      const date = new Date(period);
      date.setDate(date.getDate() + 10);
      try {
        const result = await request("/tabel", "GET", { period: date }, {});
        setListDocs(!result.records ? [] : result.records);
      } catch (e) {}
    },
    [request]
  );

  const translation = useTranslation([
    { group: "tabelList", name: "month" },
    { group: "tabelList", name: "department" },
    { group: "common", name: "search" },
  ]);

  const selectHandle = (e) => {
    const key = e.target.id || e.target.parentElement.id;
    navigate(`/timesheet/${key}`);
  };

  const onChangeSearchHendle = (e) => {
    setTextFilter(e.target.value);
  };

  useEffect(() => {
    const periodIso = period.toISOString();
    if (periodIso !== window.localStorage.getItem("period")) {
      window.localStorage.setItem("period", periodIso);
    }
    readData(period);
  }, [period]);

  if (!listDocs) {
    filteredDocs = [];
  } else if (textFilter === "") {
    filteredDocs = listDocs;
  } else {
    filteredDocs = listDocs.filter((obj) =>
      obj.department.toLowerCase().includes(textFilter.toLowerCase())
    );
  }

  return (
    <div className="pl-[5rem] pr-[1rem] min-h-[calc(100svh)] flex flex-col bg-color04">
      <div className="flex pb-4 gap-3 flex-wrap">
        <SelectMonth currentMonth={period} setCurrentMonth={setPeriod} />
        <input
          type="text"
          value={textFilter}
          className="flex flex-grow min-w-[10em] border border-color05 text-color01 text-sm rounded-lg focus:ring-color03 focus:border-color03 block p-2"
          onChange={onChangeSearchHendle}
          placeholder={translation.search}
        />
      </div>
      {loading === true && (
        <div className="absolute flex w-full mt-14 justify-center">
          <Spinner />
        </div>
      )}
      <AnimatePresence>
        <motion.table className="text-left text-sm font-light overflow-y-auto overflow-x-hidden">
          <thead className="sticky top-0 border-b font-medium bg-color04">
            <tr>
              <th scope="col" key={"month"} className="px-2 pb-1">
                <span className="without-selection cursor-default">
                  {translation.month}
                </span>
              </th>
              <th scope="col" key={"department"} className="px-2 pb-1">
                <span className="without-selection cursor-default">
                  {translation.department}
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredDocs &&
              filteredDocs.map((row) => {
                return (
                  <motion.tr
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{
                      duration: 0.05,
                      delay: 0.05 * listDocs.indexOf(row),
                    }}
                    className={
                      "transition duration-300 ease-in-out hover:bg-color03 h-10 cursor-pointer"
                    }
                    key={row.id}
                    id={row.id}
                    onClick={selectHandle}
                  >
                    <td className="whitespace-nowrap font-medium px-2 rounded-l-md">
                      {getLocalMonth(new Date(row.month_salary), currentLang)}
                    </td>
                    <td className="whitespace-nowrap font-medium px-2 rounded-r-md">
                      {row.department}
                    </td>
                  </motion.tr>
                );
              })}
          </tbody>
        </motion.table>
      </AnimatePresence>
    </div>
  );
}
