export const classificatorSettings = (type) => {
  const settings = {
    people: {
      classificatorName: "people",
      tableName: "people",
      editInList: true,
      columns: [
        {
          id: "name",
          name: "name",
          label: "peopleName",
          type: "string",
          required: true,
        },
        { id: "code", name: "code", label: "peopleCode", type: "string" },
      ],
    },
    job: {
      classificatorName: "job",
      tableName: "job",
      editInList: true,
      columns: [
        {
          id: "name",
          name: "name",
          label: "jobName",
          type: "string",
          required: true,
        },
        { id: "code", name: "code", label: "jobCode", type: "string" },
      ],
    },
    daytype: {
      classificatorName: "daytype",
      tableName: "daytype",
      editInList: true,
      columns: [
        {
          id: "id",
          name: "id",
          label: "dayTypeId",
          type: "string",
          isAdditional: true,
        },
        {
          id: "name",
          name: "name",
          label: "dayTypeName",
          type: "string",
          isAdditional: true,
        },
        {
          id: "short_name",
          name: "short_name",
          label: "dayTypeShortName",
          type: "string",
        },
        {
          id: "color",
          name: "color",
          label: "dayTypeColor",
          type: "string",
        },
        {
          id: "id_company",
          name: "id_company",
          label: "dayTypeCompany",
          type: "string",
        },
      ],
    },
  };

  return settings[type];
};
