import { useState, useCallback, useEffect } from "react";
import { useTranslation } from "#hooks/lang.hook";
import { motion, AnimatePresence } from "framer-motion";
import { useHttp } from "#hooks/http.hook";
import { Spinner } from "#formComponents";
import { isoToStringWithTime, getDateInDashFormat } from "#common/dateFactory";

export function History() {
  const [statistic, setStatistic] = useState({ unique: 0, falsy: 0 });
  const [textFilter, setTextFilter] = useState("");
  const [periodFilter, setPeriodFilter] = useState(
    getDateInDashFormat(new Date())
  );
  const [listDocs, setListDocs] = useState();
  const { loading, request } = useHttp();
  let filteredData = [];

  const readData = useCallback(
    async (periodFilter) => {
      try {
        const result = await request(
          "/users/history",
          "GET",
          { created_on: periodFilter },
          {}
        );
        setListDocs(!result.records ? [] : result.records);
      } catch (e) {}
    },
    [request]
  );

  const onChangeSearchHendle = (e) => {
    setTextFilter(e.target.value);
  };

  const onChangePeriodHendle = (e) => {
    setPeriodFilter(e.target.value);
  };

  const translation = useTranslation([
    { group: "history", name: "login" },
    { group: "history", name: "status" },
    { group: "history", name: "dateCreate" },
    { group: "history", name: "unique" },
    { group: "history", name: "falsy" },
    { group: "common", name: "search" },
  ]);

  useEffect(() => {
    if (periodFilter.length !== 10) return;
    readData(periodFilter);
  }, [periodFilter]);

  useEffect(() => {
    const seen = new Set();
    const passwordFalsy = new Set();
    if (listDocs) {
      listDocs.forEach(({ login, status }) => {
        status === "SUCCSESS" && !seen.has(login) && seen.add(login);
        status === "UNKNOWN" &&
          !passwordFalsy.has(login) &&
          passwordFalsy.add(login);
      });
    }
    setStatistic({
      unique: Array.from(seen).length,
      falsy: Array.from(passwordFalsy).length,
    });
  }, [listDocs]);

  if (!listDocs) {
    filteredData = [];
  } else if (textFilter === "") {
    filteredData = listDocs;
  } else {
    filteredData = listDocs.filter(
      (obj) =>
        obj.login.toLowerCase().includes(textFilter.toLowerCase()) ||
        obj.status === textFilter
    );
  }

  return (
    <div className="pl-[5rem] pr-[1rem] min-h-[calc(100svh)] flex flex-col bg-color04">
      {loading === true && (
        <div className="absolute flex w-full mt-14 justify-center">
          <Spinner />
        </div>
      )}
      <div className="flex pb-4 gap-3 flex-wrap">
        <input
          type="text"
          value={textFilter}
          className="flex flex-grow min-w-[10em] border border-color05 text-color01 text-sm rounded-lg focus:ring-color03 focus:border-color03 block p-2"
          onChange={onChangeSearchHendle}
          placeholder={translation.search}
        />
        <input
          type="text"
          value={periodFilter}
          className="flex flex-grow min-w-[10em] border border-color05 text-color01 text-sm rounded-lg focus:ring-color03 focus:border-color03 block p-2"
          onChange={onChangePeriodHendle}
          placeholder={"yyyy-mm-dd"}
        />
      </div>
      <div className="flex pb-4 gap-3 flex-wrap">
        <div className="flex-1">
          <span className="mx-1">{translation.unique}</span>:
          <span className="mx-1">{statistic.unique}</span>
        </div>
        <div className="flex-1">
          <span className="mx-1">{translation.falsy}</span>:
          <span className="mx-1">{statistic.falsy}</span>
        </div>
      </div>
      <AnimatePresence>
        <motion.table className="text-left text-sm font-light overflow-y-auto overflow-x-hidden">
          <thead className="sticky top-0 border-b font-medium bg-color04">
            <tr>
              <th scope="col" key={"month"} className="px-2 pt-4 pb-1">
                <span className="without-selection cursor-default">
                  {translation.login}
                </span>
              </th>
              <th scope="col" key={"department"} className="px-2 pt-4 pb-1">
                <span className="without-selection cursor-default">
                  {translation.status}
                </span>
              </th>
              <th scope="col" key={"approved"} className="px-2 pt-4 pb-1">
                <span className="without-selection cursor-default">{"IP"}</span>
              </th>
              <th scope="col" key={"approved"} className="px-2 pt-4 pb-1">
                <span className="without-selection cursor-default">
                  {translation.dateCreate}
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredData &&
              filteredData.map((row, id) => {
                return (
                  <motion.tr
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{
                      duration: 0.05,
                      delay: 0.05 * listDocs.indexOf(row),
                    }}
                    className={
                      "transition duration-300 ease-in-out hover:bg-color03 h-10 cursor-default"
                    }
                    key={id}
                    id={id}
                  >
                    <td className="whitespace-nowrap font-medium px-2 rounded-l-md">
                      {row.login}
                    </td>
                    <td className="whitespace-nowrap font-medium px-2 rounded-r-md">
                      {row.status}
                    </td>
                    <td className="whitespace-nowrap font-medium px-2 rounded-r-md">
                      {row.ip}
                    </td>
                    <td className="whitespace-nowrap font-medium px-2 rounded-r-md">
                      {isoToStringWithTime(row.created_on)}
                    </td>
                  </motion.tr>
                );
              })}
          </tbody>
        </motion.table>
      </AnimatePresence>
    </div>
  );
}
