import "./App.css";
import { Route, Routes } from "react-router-dom";
import { Home } from "./pages/Home";
import { Auth } from "./pages/Auth";
import {
  Classificator,
  History,
  Database,
  Layout,
  Salary,
  SalaryList,
  Settings,
  TabelList,
  Tabel,
} from "#components";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/auth" element={<Auth />} />
        <Route element={<Layout />}>
          <Route path="/payroll" element={<SalaryList />} />
          <Route path="/payroll/:id" element={<Salary />} />
          <Route path="/timesheet" element={<TabelList />} />
          <Route path="/timesheet/:id" element={<Tabel />} />
          <Route path="/history" element={<History />} />
          <Route path="/database" element={<Database />} />
          <Route path="/daytype" element={<Classificator type={"daytype"} />} />
          <Route path="/people" element={<Classificator type={"people"} />} />
          <Route path="/job" element={<Classificator type={"job"} />} />
          <Route path="/users" element={<Classificator type={"users"} />} />
          <Route path="/settings" element={<Settings />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
