import { motion, AnimatePresence } from "framer-motion";
import { useState, useCallback, useEffect, useRef } from "react";
import { useTranslation } from "#hooks/lang.hook";
import { useHttp } from "#hooks/http.hook";
import { showAlert } from "#features/alertSlice";
import { useDispatch } from "react-redux";
import { Spinner, Button } from "#formComponents";
import { PicPlus, PicTrashbin } from "#svg";
import { classificatorSettings } from "#classificatorSettings";
import { Alert } from "#formComponents";

export function Classificator({ type }) {
  const { classificatorName, tableName, columns, editInList } =
    classificatorSettings(type);
  const firstEditField = useRef();
  const addedNewRec = useRef(false);
  const [textFilter, setTextFilter] = useState("");
  const [dataTable, setDataTable] = useState([]);
  const [currentRow, setCurrentRow] = useState();
  const [showSaveButton, setShowSaveButton] = useState(false);
  const { loading, request } = useHttp();
  const dispatch = useDispatch();
  const translation = useTranslation([
    ...columns.map((rowCol) => ({
      group: "classificator",
      name: rowCol.label,
    })),
    { group: "common", name: "search" },
    { group: "common", name: "save" },
    { group: "classificator", name: classificatorName },
    { group: "error", name: "requiredField" },
  ]);
  let filteredData = [];

  const getAlert = useCallback(
    (textAlert) => {
      dispatch(showAlert(textAlert));
    },
    [dispatch]
  );

  const readData = useCallback(
    async (tableName) => {
      try {
        const result = await request(`/${tableName}`, "GET", {}, {});
        if (!result.error) {
          setDataTable(result.records);
        } else {
          setDataTable([]);
        }
      } catch (e) {}
    },
    [request]
  );

  const controlRequired = (table) => {
    const columnsToCheck = columns.filter((col) => col.required === true);
    if (columnsToCheck.length === 0) return true;
    let problems;
    let result = true;

    table.forEach((row) => {
      problems = columnsToCheck.filter((col) => !row[col.name]);
      if (problems.length && result === true) {
        result = false;
      }
    });

    if (result === false) getAlert(translation.requiredField);
    return result;
  };

  const saveData = useCallback(
    async (dataTable, tableName) => {
      const dataAdd = dataTable.filter((row) => row.added === true);
      const dataEdit = dataTable.filter(
        (row) => row.added !== true && row.edited === true
      );

      let isNoErrors = controlRequired(dataAdd);
      if (isNoErrors === false) return;
      isNoErrors = controlRequired(dataEdit);
      if (isNoErrors === false) return;

      if (dataEdit && dataEdit.length) {
        try {
          const result = await request(
            `/${tableName}`,
            "POST",
            {
              data: dataEdit,
            },
            {}
          );
          if (!result.error) {
            setDataTable((prev) =>
              prev.map((row) => ({ ...row, edited: false }))
            );
          }
        } catch (e) {}
      }
      if (dataAdd && dataAdd.length) {
        try {
          const resultAddition = await request(
            `/${tableName}`,
            "PUT",
            {
              data: dataAdd,
            },
            {}
          );
          if (!resultAddition.error) {
            readData();
          }
        } catch (e) {}
      }
    },
    [request]
  );

  const deleteData = useCallback(
    async (currentRow, tableName) => {
      try {
        const result = await request(
          `/${tableName}`,
          "DELETE",
          { id: currentRow },
          {}
        );
        if (result.records.length) {
          setDataTable((prev) => prev.filter((row) => row.id !== currentRow));
        }
      } catch (e) {}
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [request]
  );

  const saveHandle = (e) => {
    saveData(dataTable, tableName);
  };

  const selectHandle = (e) => {
    const key =
      e.target.id ||
      e.target.parentElement.id ||
      e.target.parentElement.parentElement.id ||
      e.target.parentElement.parentElement.parentElement.id;
    setCurrentRow(key);
  };

  const addHandle = (e) => {
    const newId = new Date().getTime().toString();
    const newObj = {
      id: newId,
      added: true,
      edited: true,
    };
    columns.forEach((row) => (newObj[row] = ""));
    addedNewRec.current = true;
    setDataTable((prev) => [...prev, newObj]);
    setCurrentRow(newId);
  };

  const deleteHandle = (e) => {
    const currRow = dataTable.find((row) => row.id === currentRow);
    if (currRow) {
      if (currRow.added === true)
        setDataTable((prev) => prev.filter((row) => row.id !== currentRow));
      else deleteData(currentRow, tableName);
    }
  };

  const onChangeHandle = (e, row_id, column_name) => {
    setDataTable((prev) =>
      prev.map((row) =>
        row.id === row_id
          ? { ...row, [column_name]: e.target.value, edited: true }
          : row
      )
    );
  };

  const onChangeSearchHendle = (e) => {
    setTextFilter(e.target.value);
  };

  useEffect(() => {
    setShowSaveButton(
      dataTable.length > 0
        ? dataTable.filter((row) => row.edited === true).length > 0
        : false
    );
    if (addedNewRec.current === true) {
      firstEditField.current.focus();
      addedNewRec.current = false;
    }
  }, [dataTable]);

  useEffect(() => {
    readData(tableName);
  }, [readData, type, tableName]);

  if (textFilter === "") {
    filteredData = dataTable;
  } else {
    filteredData = dataTable.filter(
      (obj) =>
        columns
          .map((rowCol) =>
            obj[rowCol.name].toLowerCase().includes(textFilter.toLowerCase())
          )
          .find((rowFind) => rowFind === true) === true
    );
  }

  return (
    <div className="pl-[5rem] pr-[1rem] min-h-[calc(100svh)] flex flex-col bg-color04">
      <Alert />
      {loading === true && (
        <div className="absolute flex w-full mt-12 justify-center">
          <Spinner />
        </div>
      )}
      <div className="sticky top-0 bg-color04">
        <div className="flex mx-2 h-6">
          <span className="font-bold text-lg">
            {translation[classificatorName]}
          </span>
        </div>
        <div className="flex gap-3 mx-2 mt-3 mb-5 h-10">
          <input
            type="text"
            value={textFilter}
            className="flex flex-grow min-w-[10em] border border-color05 text-color01 text-sm rounded-lg focus:ring-color03 focus:border-color03 block p-2"
            onChange={onChangeSearchHendle}
            placeholder={translation.search}
          />
          <Button onClickHandle={addHandle} icon={<PicPlus />} size="small" />
          {editInList === true && (
            <Button
              onClickHandle={deleteHandle}
              icon={<PicTrashbin />}
              size="small"
            />
          )}

          {showSaveButton === true && (
            <Button
              onClickHandle={saveHandle}
              label={translation.save}
              size="small"
            />
          )}
        </div>
      </div>

      <AnimatePresence>
        <motion.table className="text-left text-sm font-light mx-2">
          <thead className="sticky top-[6rem] border-b font-medium">
            <tr className="bg-color04">
              <th className="w-3" key={"col_id"}></th>
              {columns &&
                columns.map((rowColumn) => (
                  <th
                    scope="col"
                    key={rowColumn.id}
                    className={"without-selection px-2".concat(
                      rowColumn.isAdditional ? " hidden sm:table-cell" : ""
                    )}
                  >
                    {translation[rowColumn.label]}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {filteredData?.map((row) => {
              return (
                <motion.tr
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{
                    duration: 0.05,
                    delay: 0.05 * filteredData.indexOf(row),
                  }}
                  className={"transition duration-300 ease-in-out hover:bg-color03 h-10 rounded-lg".concat(
                    currentRow === row.id
                      ? " bg-color03 border-l border-color01"
                      : ""
                  )}
                  key={row.id}
                  id={row.id}
                  onClick={selectHandle}
                >
                  <td className="w-3" key={"col_id"}>
                    {row.added ? "+" : row.edited ? "*" : ""}
                  </td>
                  {columns.map((rowColumn, indexColumn) => (
                    <td
                      className={"whitespace-nowrap font-medium px-2 cursor-default"
                        .concat(
                          rowColumn.isAdditional ? " hidden sm:table-cell" : ""
                        )
                        .concat(
                          rowColumn.required === true && !row[rowColumn.name]
                            ? " border-b-4 border-colorRed"
                            : ""
                        )}
                      key={`col${rowColumn.id}`}
                    >
                      {editInList === true ? (
                        <input
                          type={"text"}
                          value={row[rowColumn.name] ? row[rowColumn.name] : ""}
                          ref={indexColumn === 0 ? firstEditField : null}
                          onChange={(e) =>
                            onChangeHandle(e, row.id, rowColumn.name)
                          }
                          className="bg-transparent text-color01 text-sm w-full p-2 my-0.5 cursor-pointer"
                        />
                      ) : (
                        row[rowColumn.name]
                      )}
                    </td>
                  ))}
                </motion.tr>
              );
            })}
          </tbody>
        </motion.table>
      </AnimatePresence>
    </div>
  );
}
