export { MenuChat } from "#svg/menu/MenuChat";
export { MenuClassificators } from "#svg/menu/MenuClassificators";
export { MenuClients } from "#svg/menu/MenuClients";
export { MenuContract } from "#svg/menu/MenuContract";
export { MenuDayType } from "#svg/menu/MenuDayType";
export { MenuErrorReport } from "#svg/menu/MenuErrorReport";
export { MenuHistory } from "#svg/menu/MenuHistory";
export { MenuHome } from "#svg/menu/MenuHome";
export { MenuLogoff } from "#svg/menu/MenuLogoff";
export { MenuPayments } from "#svg/menu/MenuPayments";
export { MenuSalary } from "#svg/menu/MenuSalary";
export { MenuSchedule } from "#svg/menu/MenuSchedule";
export { MenuSettings } from "#svg/menu/MenuSettings";
