import { useEffect, useState, useCallback } from "react";
import { useTranslation } from "#hooks/lang.hook";
import { motion, AnimatePresence } from "framer-motion";
import { Spinner, Button, Breadcrumb, Alert } from "#formComponents";
import { useParams } from "react-router-dom";
import { formatToDeciamal } from "#common/formatFactory";
import { useHttp } from "#hooks/http.hook";
import { showAlert } from "#features/alertSlice";
import { useDispatch } from "react-redux";
import { isoToStringWithTime } from "#common/dateFactory";
import { useNavigate } from "react-router-dom";

export function RowSalary({
  row,
  curRow,
  index,
  setPeople,
  selectHandle,
  approved,
}) {
  const [numValue, setNumValue] = useState(
    formatToDeciamal(row?.salaryApproved) || "0.00"
  );
  const [noteValue, setNoteValue] = useState(row?.comment || "");
  const [refreshValue, setRefreshValue] = useState(false);

  useEffect(() => {
    setNumValue(formatToDeciamal(row?.salaryApproved) || "0.00");
  }, [row]);

  useEffect(() => {
    if (refreshValue === true) {
      if (row.salaryApproved !== Number(numValue)) {
        setPeople((prev) =>
          prev.map((rowPrev) =>
            rowPrev.emploee_id.toString() === row.emploee_id.toString()
              ? { ...rowPrev, salaryApproved: Number(numValue) }
              : rowPrev
          )
        );
      }
      setRefreshValue(false);
    }
  }, [refreshValue]);

  const handleBlurNote = (e) => {
    if (row.comment !== noteValue) {
      setPeople((prev) =>
        prev.map((rowPrev) =>
          rowPrev.emploee_id.toString() === row.emploee_id.toString()
            ? { ...rowPrev, comment: noteValue }
            : rowPrev
        )
      );
    }
  };

  const onChangeHandle = (e) => {
    const key = e.target.value;
    setNoteValue(key);
  };

  const handleNumValueChange = (e) => {
    let val = e.target.value;
    val = val.replace(/[^0-9.]/g, "");

    const parts = val.split(".");

    if (parts[0].length > 7) {
      parts[0] = parts[0].slice(0, 7);
    }

    if (parts[1]?.length > 2) {
      parts[1] = parts[1].slice(0, 2);
    }
    const res = parts.join(".");

    setNumValue(res);
  };

  const handleBlur = () => {
    setNumValue((prev) => {
      const lastData = formatToDeciamal(prev);
      setRefreshValue(true);
      return lastData;
    });
  };

  return (
    <motion.tr
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      transition={{
        duration: 0.05,
        delay: 0.05 * index,
      }}
      className={"transition duration-300 ease-in-out h-10 group"}
      key={row.emploee_id}
      id={row.emploee_id}
      onClick={selectHandle}
    >
      <td
        className={"whitespace-nowrap font-medium px-2 rounded-l-md cursor-default group-hover:bg-color03".concat(
          curRow ? " bg-color03 " : " bg-color04 "
        )}
      >
        {row.department}
      </td>
      <td
        className={"whitespace-nowrap font-medium px-2 cursor-default group-hover:bg-color03".concat(
          curRow ? " bg-color03 " : " bg-color04 "
        )}
      >
        {row.position}
      </td>
      <td
        className={"sticky left-0 whitespace-nowrap font-medium px-2 cursor-default group-hover:bg-color03".concat(
          curRow ? " bg-color03 " : " bg-color04 "
        )}
      >
        {row.name}
      </td>
      {/* <td
        className={"whitespace-nowrap w-[8rem] font-medium px-2 cursor-default group-hover:bg-color03".concat(
          curRow ? " bg-color03 " : " bg-color04 "
        )}
      >
        {row.dateStart}
      </td> */}
      <td
        className={"text-right whitespace-nowrap font-medium px-2 cursor-default group-hover:bg-color03".concat(
          curRow ? " bg-color03 " : " bg-color04 "
        )}
      >
        {formatToDeciamal(row.salarySumm)}
      </td>
      <td
        className={"text-right w-[10rem] whitespace-nowrap font-medium px-2 cursor-default group-hover:bg-color03".concat(
          curRow ? " bg-color03 " : approved ? "" : " bg-color05 "
        )}
      >
        {approved ? (
          <span>{numValue}</span>
        ) : (
          <input
            type={"text"}
            value={numValue}
            onChange={handleNumValueChange}
            onBlur={handleBlur}
            placeholder={row.salarySumm}
            className="text-right bg-transparent font-bold text-color01 text-sm w-full p-2 my-0.5 cursor-pointer"
          />
        )}
      </td>
      <td
        className={"text-right whitespace-nowrap font-medium px-2 cursor-default group-hover:bg-color03".concat(
          curRow ? " bg-color03 " : " bg-color04 "
        )}
      >
        {`${formatToDeciamal(
          (Number(row.salaryApproved) - Number(row.salarySumm)).toFixed(2)
        )} (${
          Number(row.salarySumm) === 0
            ? "0"
            : (
                ((Number(row.salaryApproved) - Number(row.salarySumm)) /
                  Number(row.salarySumm)) *
                100
              ).toFixed(0)
        }%)`}
      </td>
      <td
        className={"whitespace-nowrap font-medium px-2 rounded-r-md cursor-default group-hover:bg-color03".concat(
          curRow ? " bg-color03 " : approved ? "" : " bg-color05 "
        )}
      >
        {approved ? (
          <span>{noteValue}</span>
        ) : (
          <input
            type={"text"}
            value={noteValue}
            onChange={onChangeHandle}
            onBlur={handleBlurNote}
            className="bg-transparent text-color01 text-sm w-full p-2 my-0.5 cursor-pointer"
          />
        )}
      </td>
    </motion.tr>
  );
}

export function Salary() {
  const { loading, request } = useHttp();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [edited, setEdited] = useState(false);
  const [approved, setApproved] = useState(false);
  const [currentRow, setCurrentRow] = useState();
  const [people, setPeople] = useState([]);
  const [docNumber, setDocNumber] = useState("");
  const [textFilter, setTextFilter] = useState("");
  let filteredPeople = [];

  const translation = useTranslation([
    { group: "salary", name: "employee" },
    { group: "salary", name: "approve" },
    { group: "salary", name: "department" },
    { group: "salary", name: "position" },
    { group: "salary", name: "date" },
    { group: "salary", name: "base" },
    { group: "salary", name: "approved" },
    { group: "salary", name: "result" },
    { group: "salary", name: "comments" },
    { group: "salary", name: "copy" },
    { group: "common", name: "search" },
    { group: "common", name: "save" },
    { group: "menu", name: "salarylist" },
    { group: "salary", name: "userApproved" },
  ]);

  const getAlert = useCallback(
    (textAlert) => {
      dispatch(showAlert(textAlert));
    },
    [dispatch]
  );

  const readData = useCallback(
    async (id) => {
      try {
        const result = await request(`/salary/${id}`, "GET", "", {});
        setEdited(undefined);
        if (result?.records?.length) {
          const currentDoc = result.records[0];
          setDocNumber(
            `${currentDoc.doc_number} ${
              currentDoc.useraprove_id
                ? `: ${translation.userApproved} ${
                    currentDoc.name
                  } (${isoToStringWithTime(currentDoc.date_approve)})`
                : ``
            }`
          );
          setApproved(!currentDoc.useraprove_id ? false : true);
          setPeople(currentDoc.details.data);
        } else {
          setDocNumber("-");
          setPeople([]);
        }
      } catch (e) {}
    },
    [request]
  );

  const saveData = useCallback(
    async (id, people, approve = false) => {
      if (people && people.length) {
        try {
          await request(
            "/salary",
            "POST",
            {
              id: id,
              data: JSON.stringify(people),
              approve,
            },
            {}
          );
          setEdited(false);
          if (approve) {
            navigate("/payroll");
          }
        } catch (e) {}
      }
    },
    [request]
  );

  const selectHandle = (e) => {
    const key =
      e.target.id ||
      e.target.parentElement.id ||
      e.target.parentElement.parentElement.id;
    setCurrentRow(key);
  };

  const onChangeSearchHendle = (e) => {
    setTextFilter(e.target.value);
  };

  const copyZPHandle = (e) => {
    setPeople((allPeople) =>
      allPeople.map((rowPeople) => ({
        ...rowPeople,
        salaryApproved: rowPeople.salaryApproved
          ? rowPeople.salaryApproved
          : rowPeople.salarySumm,
      }))
    );
  };

  const saveHandle = (e) => {
    saveData(id, people);
  };

  const applyHandle = (e) => {
    const errNames = [];
    people.forEach((row) => {
      if (
        Number(row.salaryApproved) - Number(row.salarySumm) !== 0 &&
        row.comment === ""
      ) {
        errNames.push(row.name);
      }
    });
    if (errNames.length) {
      getAlert(
        `Треба вказати примітки для наступних співробітників: ${errNames.join(
          ", "
        )}!`
      );
    } else {
      saveData(id, people, true);
    }
  };

  useEffect(() => {
    readData(id);
  }, [id]);

  useEffect(() => {
    setEdited((prev) => (prev === undefined ? false : true));
  }, [people]);

  if (textFilter === "") {
    filteredPeople = people;
  } else {
    filteredPeople = people.filter(
      (obj) =>
        obj.name.toLowerCase().includes(textFilter.toLowerCase()) ||
        obj.department.toLowerCase().includes(textFilter.toLowerCase()) ||
        obj.position.toLowerCase().includes(textFilter.toLowerCase())
    );
  }

  return (
    <div className="pl-[5rem] pr-[1rem] py-[1rem] min-h-[calc(100svh)] flex flex-col bg-color04 gap-3">
      <Alert />
      {loading === true && (
        <div className="absolute flex w-full mt-12 justify-center">
          <Spinner />
        </div>
      )}
      <Breadcrumb
        arr={[
          { href: "/payroll", name: translation.salarylist },
          { href: "#", name: docNumber },
        ]}
      />
      <div className="flex flex-wrap items-center gap-3 mb-1">
        {edited && approved === false && (
          <Button
            onClickHandle={saveHandle}
            label={translation.save}
            size="small"
          />
        )}
        {approved === false && (
          <Button
            onClickHandle={copyZPHandle}
            label={translation.copy}
            size="small"
          />
        )}
        <input
          type="text"
          value={textFilter}
          className="flex flex-grow min-w-[10em] border border-color05 text-color01 text-sm rounded-lg focus:ring-color03 focus:border-color03 block p-2"
          onChange={onChangeSearchHendle}
          placeholder={translation.search}
        />
        {approved === false && (
          <Button
            onClickHandle={applyHandle}
            label={translation.approve}
            size="small"
          />
        )}
      </div>

      <AnimatePresence>
        <motion.table className="text-left text-sm font-light overflow-y-auto overflow-x-hidden">
          <thead className="sticky top-0 border-b font-medium bg-color04">
            <tr>
              <th scope="col" key={"department"} className="px-2 pt-4 pb-1">
                <span className="without-selection cursor-default">
                  {translation.department}
                </span>
              </th>
              <th scope="col" key={"position"} className="px-2 pt-4 pb-1">
                <span className="without-selection cursor-default">
                  {translation.position}
                </span>
              </th>
              <th
                scope="col"
                key={"employee"}
                className="sticky left-0 px-2 pt-4 pb-1 bg-color04 z-3 cursor-default"
              >
                <span className="without-selection">
                  {translation.employee}
                </span>
              </th>
              {/* <th scope="col" key={"date"} className="w-[8rem] px-2 pt-4 pb-1">
                <span className="without-selection cursor-default">
                  {translation.date}
                </span>
              </th> */}
              <th scope="col" key={"base"} className="px-2 pt-4 pb-1">
                <span className="without-selection cursor-default">
                  {translation.base}
                </span>
              </th>
              <th
                scope="col"
                key={"approved"}
                className={"px-2 pt-4 pb-1".concat(
                  approved === false ? " bg-color05" : ""
                )}
              >
                <span className="without-selection cursor-default">
                  {translation.approved}
                </span>
              </th>
              <th scope="col" key={"result"} className="px-2 pt-4 pb-1">
                <span className="without-selection cursor-default">
                  {translation.result}
                </span>
              </th>
              <th
                scope="col"
                key={"comments"}
                className={"px-2 pt-4 pb-1".concat(
                  approved === false ? " bg-color05" : ""
                )}
              >
                <span className="without-selection cursor-default">
                  {translation.comments}
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredPeople &&
              filteredPeople.map((row) => (
                <RowSalary
                  key={row.emploee_id}
                  row={row}
                  curRow={currentRow === row.emploee_id.toString()}
                  currentRow={currentRow}
                  index={filteredPeople.indexOf(row)}
                  setPeople={setPeople}
                  selectHandle={selectHandle}
                  approved={approved}
                />
              ))}
          </tbody>
        </motion.table>
      </AnimatePresence>
    </div>
  );
}
